import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "crafted-reading---freedom-and-open-source",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading - Freedom and Open Source`}<a parentName="h1" {...{
        "href": "#crafted-reading---freedom-and-open-source",
        "aria-label": "crafted reading   freedom and open source permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://ncase.me/loopy/" mdxType="Link">
        <h4 {...{
          "id": "loopy",
          "style": {
            "position": "relative"
          }
        }}>{`Loopy`}<a parentName="h4" {...{
            "href": "#loopy",
            "aria-label": "loopy permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Nicky Case's Loopy helps us think in systems via an easy-to-use web app. Great for illustrating and sharing simple systems.`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "free-our-items",
      "style": {
        "position": "relative"
      }
    }}>{`Free Our Items`}<a parentName="h2" {...{
        "href": "#free-our-items",
        "aria-label": "free our items permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`In 2018, the idea of NFTs being used in games started to gain traction. This was largely due to the success of `}<a parentName="p" {...{
        "href": "https://www.cryptokitties.co/technical-details"
      }}>{`Cryptokitties`}</a>{`, and with the several startups that started to explore what the technology means for current game ecosystems.`}</p>
    <List mdxType="List">
      <Link to="https://blog.hoard.exchange/diablo-iiis-failed-auction-house-why-true-ownership-won-t-save-your-game-c6d692b9de1" mdxType="Link">
        <p>{`Diablo III’s failed Auction House: Why true ownership won’t save your game`}</p>
      </Link>
      <Link to="https://medium.com/hackernoon/building-a-cross-game-item-future-3ce16f3aea7c" mdxType="Link">
        <p>{`Building A Cross-Game Item Future`}</p>
      </Link>
      <Link to="https://hackernoon.com/how-to-build-a-robust-game-economy-lessons-from-one-of-the-worlds-longest-running-mmos-426f8fd94f6d" mdxType="Link">
        <p>{`How to build a robust game economy: Lessons from one of the world’s longest running MMOs`}</p>
      </Link>
      <Link to="https://tonysheng.substack.com/p/crypto-adoption-games" mdxType="Link">
        <p>{`If games drive crypto mass adoption, they will be grassroots`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "open-source-everything",
      "style": {
        "position": "relative"
      }
    }}>{`Open Source Everything`}<a parentName="h2" {...{
        "href": "#open-source-everything",
        "aria-label": "open source everything permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="https://en.wikipedia.org/wiki/Pathetic_dot_theory" mdxType="Link">
        <h4 {...{
          "id": "pathetic-dot-theory",
          "style": {
            "position": "relative"
          }
        }}>{`Pathetic Dot Theory`}<a parentName="h4" {...{
            "href": "#pathetic-dot-theory",
            "aria-label": "pathetic dot theory permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Created by Lawrence Lessig, a relevant mental model for thinking of the systems that affect us and the products we build.`}</p>
      </Link>
      <Link to="https://www.interdependence.online/declaration/e-bw-AGkYsZFYqmAe2771A6hi9ZMIkWrkBNtHIF1hF4" mdxType="Link">
        <h4 {...{
          "id": "a-declaration-of-the-interdependence-of-cyberspace",
          "style": {
            "position": "relative"
          }
        }}>{`A Declaration of the Interdependence of Cyberspace`}<a parentName="h4" {...{
            "href": "#a-declaration-of-the-interdependence-of-cyberspace",
            "aria-label": "a declaration of the interdependence of cyberspace permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <blockquote>
          <p parentName="blockquote">{`Closed Fiefdoms of the platform world, you weary giants of stocks and small talk, I come from the Pluriverse, the new home of the Heart. On behalf of the future, I invite you to join us.`}</p>
        </blockquote>
      </Link>
      <Link to="https://www.socialtext.net/codev2/code_is_law" mdxType="Link">
        <h4 {...{
          "id": "code-and-other-laws-of-cyberspace-by-lawrence-lessig",
          "style": {
            "position": "relative"
          }
        }}>{`Code and Other Laws of Cyberspace by Lawrence Lessig`}<a parentName="h4" {...{
            "href": "#code-and-other-laws-of-cyberspace-by-lawrence-lessig",
            "aria-label": "code and other laws of cyberspace by lawrence lessig permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <blockquote>
          <p parentName="blockquote">{`[The code]`}{` will present the greatest threat to both liberal and libertarian ideals, as well as their greatest promise. We can build, or architect, or code cyberspace to protect values that we believe are fundamental. Or we can build, or architect, or code cyberspace to allow those values to disappear. There is no middle ground. There is no choice that does not include some kind of building. Code is never found; it is only ever made, and only ever made by us.`}</p>
        </blockquote>
      </Link>
      <Link to="https://solidproject.org/about" mdxType="Link">
        <h4 {...{
          "id": "solid-by-sir-tim-berners-lee",
          "style": {
            "position": "relative"
          }
        }}>{`Solid by Sir Tim Berners-Lee`}<a parentName="h4" {...{
            "href": "#solid-by-sir-tim-berners-lee",
            "aria-label": "solid by sir tim berners lee permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <blockquote>
          <p parentName="blockquote">{`Solid is a mid-course correction for the Web by its inventor, Sir Tim Berners-Lee. It realizes Tim’s original vision for the Web as a medium for the secure, decentralized exchange of public and private data.`}</p>
        </blockquote>
      </Link>
    </List>
    <h2 {...{
      "id": "breakout-activity",
      "style": {
        "position": "relative"
      }
    }}>{`Breakout Activity`}<a parentName="h2" {...{
        "href": "#breakout-activity",
        "aria-label": "breakout activity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`For this week, we'll be drawing 🟠 circles and ↗ arrows. `}</p>
    <p>{`Below is Nicky Case's diagram of interconnected systems. We've tried to identify Web 3 concepts and technologies that could be part of each system and have added them beside their respective nodes. `}</p>
    <p>{`Where would your product fit in this diagram? Consider which systems your product affects and which ones affect it back.`}</p>
    <p>{`Don't hesitate to add or remove nodes and arrows. This is just a model to help you think through systems, and there is no correct answer.`}</p>
    <p>{`Click on the `}<inlineCode parentName="p">{`🔀 REMIX`}</inlineCode>{` button to begin. `}</p>
    <iframe width="100%" height="600" frameBorder="0" src="https://ncase.me/loopy/v1.1/?embed=1&data=[[[1,672,551,1,%22Technology%22,4],[2,669,119,1,%22Policy%22,0],[3,395,332,1,%22Culture%22,2],[4,944,330,1,%22Economics%22,3],[5,131,404,1,%22Cryptoart%22,2],[6,282,397,1,%22Social%2520Tokens%22,2],[7,253,540,1,%22Sustainability%22,2],[8,215,251,1,%22Memes%22,2],[9,838,70,1,%22Smart%2520Contracts%22,0],[10,494,75,1,%22Regulation%22,0],[11,1016,144,1,%22OS%2520Foundations%22,0],[12,326,133,1,%22DAOs%22,0],[13,1208,370,1,%22Discoverability%22,3],[14,1089,281,1,%22Gamification%22,3],[15,541,619,1,%22Blockchains%22,4],[16,813,622,1,%22Decentralized%2520Storage%22,4],[17,1153,582,1,%22DeFi%22,3],[18,1054,440,1,%22Radical%2520Economics%22,3],[19,415,533,1,%22Zero%2520Knowledge%22,4],[20,942,543,1,%22Protocols%22,4]],[[1,2,61,1,0],[2,1,49,1,0],[3,2,58,1,0],[2,3,37,1,0],[3,1,-60,1,0],[1,3,-53,1,0],[1,4,-69,1,0],[1,4,40,1,0],[4,2,39,1,0],[2,4,57,1,0],[3,4,-49,1,0],[4,3,-55,1,0]],[],20%5D"></iframe>
    <p>{`Once done, please share your diagrams on Slack. It will be useful learning also for your peers! 🙌`}</p>
    <h2 {...{
      "id": "going-further",
      "style": {
        "position": "relative"
      }
    }}>{`Going Further`}<a parentName="h2" {...{
        "href": "#going-further",
        "aria-label": "going further permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`If you'd like to model more intricate systems you can taka a look at `}<a parentName="p" {...{
        "href": "/track-gaming/module-3/curated/#machinations"
      }}>{`Machinations`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      